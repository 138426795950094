import { DateTime } from "luxon";

function dateParaDiaMesAno(isoDataString) {
  const formato = "dd/MM/yyyy";

  try {
    if (typeof isoDataString === "string") {
      const data = DateTime.fromISO(isoDataString).toFormat(formato).toString();
      return data;
    } else if (isoDataString instanceof Date) {
      const data = DateTime.fromJSDate(isoDataString)
        .toFormat(formato)
        .toString();

      return data;
    }
  } catch (error) {
    console.error("Formato de data errado:", isoDataString);
  }

  return "";
}

function timestampParaDiaMesAnoHoraMinuto(timestamp) {
  return DateTime.fromFormat(timestamp, "dd/MM/yyyy'T'HH:mm:ss'Z'", {
    zone: "utc",
  })
    .toFormat("dd/MM/yyyy' - 'HH'h'mm")
    .toString();
}

export { dateParaDiaMesAno, timestampParaDiaMesAnoHoraMinuto };
